// Settings.js
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, Avatar, Stack, Box, Button } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const Settings = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addingMinutes, setAddingMinutes] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.track('Settings Page Viewed');

    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
            amplitude.track('User Data Fetched', { userId: user.uid, email: user.email });
          } else {
            console.error('User not found');
            amplitude.track('Error: User Data Not Found', { userId: user.uid });
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          amplitude.track('Error Fetching User Data', { error: error.message });
        }
      }
      setLoading(false);
    };

    fetchUserDetails();
  }, []);

  const handleAddMinutes = async () => {
    setAddingMinutes(true);
    const functions = getFunctions();
    const createOrRetrieveStripeCustomer = httpsCallable(functions, 'createOrRetrieveStripeCustomer');

    try {
      const { data } = await createOrRetrieveStripeCustomer();
      amplitude.track('Add Minutes Clicked', { userId: auth.currentUser?.uid });
      navigate('/pricing', { state: { clientSecret: data.clientSecret } });
    } catch (error) {
      console.error('Error adding minutes:', error);
      amplitude.track('Error Adding Minutes', { error: error.message });
    } finally {
      setAddingMinutes(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      amplitude.track('User Logged Out', { userId: auth.currentUser?.uid });
    } catch (error) {
      console.error('Error signing out:', error);
      amplitude.track('Error Logging Out', { error: error.message });
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const timeParts = [];
    if (hours > 0) timeParts.push(`${hours} ժամ`);
    timeParts.push(`${minutes} րոպե`);  // Always display minutes
    if (secs > 0) timeParts.push(`${secs} վայրկյան`);

    return timeParts.join(', ');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ padding: 3, boxShadow: 3, maxWidth: 500, margin: '0 auto', mt: 5 }}>
      <CardContent>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Avatar 
            src={userData?.photoURL || ''}
            alt="User Profile"
            sx={{ width: 80, height: 80, boxShadow: 2 }}
          />
          <Box textAlign="center">
            <Typography variant="h6">{userData?.displayName || 'Անանուն'}</Typography>
            <Typography color="text.secondary">{userData?.email}</Typography>
          </Box>
        </Stack>
        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
            Մնացել է {formatTime(userData?.remaining_seconds || 0)}
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 2, backgroundColor: '#ff5722', '&:hover': { backgroundColor: '#e64a19' } }}
            onClick={handleAddMinutes}
            disabled={addingMinutes}
          >
            {addingMinutes ? <CircularProgress size={24} color="inherit" /> : 'Ավելացնել'}
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          <Button variant="outlined" color="error" onClick={handleLogout} size="small">
            Դուրս գալ
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Settings;
