import React, { useState } from 'react';
import { Button, Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HistoryIcon from '@mui/icons-material/History';
import * as amplitude from '@amplitude/analytics-browser';

const FeatureItem = ({ icon, title }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
    {icon}
    <Typography variant="h6" sx={{ ml: 2 }}>
      {title}
    </Typography>
  </Box>
);

const Login = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      amplitude.track('Login Attempted', { method: 'Google' }); // Track login attempt
      await signInWithPopup(auth, provider);
      amplitude.track('Login Successful', { method: 'Google' }); // Track successful login
    } catch (error) {
      console.error("Error signing in with Google:", error);
      amplitude.track('Login Failed', {
        method: 'Google',
        error: error.message, // Capture error message
      }); // Track login failure
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0 }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            backgroundColor: '#fff',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: 400,
              width: '100%',
            }}
          >
            <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center">
              Բարի գալուստ Xosum.am
            </Typography>
            <Button
              variant="contained"
              onClick={handleGoogleSignIn}
              fullWidth
              sx={{
                mt: 4,
                mb: 2,
                py: 1.5,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#4285F4',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#357ae8',
                },
              }}
              disabled={loading}
            >
              <img
                src="https://www.gstatic.com/images/branding/product/1x/gsa_64dp.png"
                alt="Google Logo"
                style={{ width: '24px', marginRight: '10px' }}
              />
              {loading ? 'Մուտք ենք գործում...' : 'Շարունակել Google-ով'}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 4,
            height: '100%',
          }}
        >
          <Box sx={{ maxWidth: 400, mx: 'auto' }}>
            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom color="primary">
              Ինչու՞ օգտագործել Xosum.am
            </Typography>
            
            <FeatureItem 
              icon={<CloudUploadIcon sx={{ fontSize: 40, color: '#4285F4' }} />}
              title="Վերբեռնեք և վերծանեք մեծ ֆայլեր"
            />
            
            <FeatureItem 
              icon={<PhoneIphoneIcon sx={{ fontSize: 40, color: '#4285F4' }} />}
              title="Օգտագործեք բջջային սարքերում"
            />
            
            <FeatureItem 
              icon={<HistoryIcon sx={{ fontSize: 40, color: '#4285F4' }} />}
              title="Տեսեք ձեր ձայնագրությունների պատմությունը"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
