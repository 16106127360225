import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Upload from './pages/Upload';
import History from './pages/History';
import RecordAudio from './pages/RecordAudio';
import Settings from './pages/Settings';
import Login from './pages/Login';
import Pricing from './pages/Pricing';
import RecordingDetails from './pages/RecordingDetails';
import PaymentSuccess from './pages/PaymentSuccess';
import { Box, Toolbar } from '@mui/material';
import { auth } from './firebase';
import Clarity from '@microsoft/clarity';
import * as amplitude from '@amplitude/analytics-browser';

const AppContent = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await user.getIdToken(true);
        setUser(user);
        amplitude.track('User Signed In', { userId: user.uid, email: user.email });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Initialize Microsoft Clarity
  useEffect(() => {
    const projectId = "oybify9h4t"; // Your Clarity Project ID
    Clarity.init(projectId);
  }, []);

  // Initialize Amplitude
  useEffect(() => {
    amplitude.init('c57879646836f2336d864a17f4a56b7c', {
      autocapture: true, // Enable autocapture for element interactions
    });
  }, []);

  // Track page views
  useEffect(() => {
    amplitude.track('Page Viewed', { path: location.pathname });
    const titles = {
      '/upload': 'Վերբեռնել - xosum.am',
      '/history': 'Պատմություն - xosum.am',
      '/record': 'Ձայնագրել - xosum.am',
      '/settings': 'Կարգավորումներ - xosum.am',
      '/pricing': 'Գնառաջարկ - xosum.am',
      '/login': 'Xosum.am - Հայերեն խոսքը տեքստի վերածող գործիք',
      '/payment-success': 'Վճարումը հաջողվեց - xosum.am',
    };
    const pathName = location.pathname;
    if (pathName.startsWith('/recording-details')) {
      document.title = 'Մանրամասներ - xosum.am';
    } else {
      document.title = titles[pathName] || 'Xosum.am - Հայերեն խոսքը տեքստի վերածող գործիք';
    }
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      amplitude.track('User Logged Out'); // Track logout event
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <div>Բեռնվում է...</div>;
    }
    if (!user) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {user && <Header user={user} onLogout={handleLogout} handleDrawerToggle={handleDrawerToggle} />}
      {user && <Sidebar user={user} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
        <Toolbar />
        <Routes>
          <Route
            path="/upload"
            element={
              <ProtectedRoute>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/history"
            element={
              <ProtectedRoute>
                <History />
              </ProtectedRoute>
            }
          />
          <Route
            path="/record"
            element={
              <ProtectedRoute>
                <RecordAudio />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recording-details/:docId"
            element={
              <ProtectedRoute>
                <RecordingDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-success"
            element={
              <ProtectedRoute>
                <PaymentSuccess />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={user ? <Navigate to="/record" /> : <Login />} />
          <Route path="*" element={<Navigate to="/record" />} />
        </Routes>
      </Box>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
