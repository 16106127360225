import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Box, Avatar, Typography, Button, CircularProgress } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import { Link, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import * as amplitude from '@amplitude/analytics-browser';

const drawerWidth = 240;

const Sidebar = ({ user, mobileOpen, handleDrawerToggle }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleBuyClick = async () => {
    setLoading(true);

    if (auth.currentUser) {
      try {
        const functions = getFunctions();
        const createOrRetrieveStripeCustomer = httpsCallable(functions, 'createOrRetrieveStripeCustomer');
        const { data } = await createOrRetrieveStripeCustomer();
        amplitude.track('Buy Time Clicked', { userId: auth.currentUser.uid });
        navigate('/pricing', { state: { clientSecret: data.clientSecret } });
      } catch (error) {
        console.error('Error creating or retrieving Stripe customer:', error);
        amplitude.track('Error During Buy Time', { error: error.message });
      } finally {
        setLoading(false);
      }
    } else {
      console.log('User not authenticated');
      amplitude.track('Buy Time Click Attempt Without Authentication');
      setLoading(false);
    }
  };

  const handleNavigationClick = (destination) => {
    amplitude.track('Sidebar Navigation Clicked', { destination, userId: auth.currentUser?.uid });
    handleDrawerToggle();
  };

  const drawerContent = (
    <Box display="flex" flexDirection="column" height="100%">
      <Toolbar />
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <Avatar
          alt={user.displayName}
          src={user.photoURL}
          sx={{ width: 80, height: 80, margin: '0 auto' }}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          {user.displayName}
        </Typography>
      </Box>
      <List>
        <ListItem
          button
          component={Link}
          to="/record"
          sx={{ color: 'purple', '&:hover': { backgroundColor: '#e0e0e0' } }}
          onClick={() => handleNavigationClick('/record')}
        >
          <ListItemIcon>
            <MicIcon />
          </ListItemIcon>
          <ListItemText primary="Ձայնագրել" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/upload"
          sx={{ color: 'purple', '&:hover': { backgroundColor: '#e0e0e0' } }}
          onClick={() => handleNavigationClick('/upload')}
        >
          <ListItemIcon>
            <CloudUploadIcon />
          </ListItemIcon>
          <ListItemText primary="Վերբեռնել ֆայլ" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/history"
          sx={{ color: 'purple', '&:hover': { backgroundColor: '#e0e0e0' } }}
          onClick={() => handleNavigationClick('/history')}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Պատմություն" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/settings"
          sx={{ color: 'purple', '&:hover': { backgroundColor: '#e0e0e0' } }}
          onClick={() => handleNavigationClick('/settings')}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Կարգավորումներ" />
        </ListItem>
      </List>
      <Box sx={{ mt: 'auto', textAlign: 'center', p: 2 }}>
        <Button
          variant="contained"
          startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <PaymentIcon />}
          sx={{
            width: '100%',
            color: '#fff',
            backgroundColor: '#ff5722',
            paddingY: 1.5,
            '&:hover': { backgroundColor: '#e64a19' },
          }}
          onClick={handleBuyClick}
          disabled={loading}
        >
          ԳՆԵԼ ԺԱՄԱՆԱԿ
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
