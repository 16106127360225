// src/pages/RecordingDetails.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Chip,
  IconButton,
  Paper,
  Container,
  Stack,
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { doc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { storage, db } from '../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const RecordingDetails = () => {
  const { docId } = useParams();
  const decodedDocId = decodeURIComponent(docId);
  const [recordingDetails, setRecordingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [copyStatus, setCopyStatus] = useState('Պատճենել');
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    amplitude.track('Recording Details Page Viewed', { docId });
  
    let isMounted = true;
    const docRef = doc(db, 'recordings', decodedDocId);
  
    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (isMounted) {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setRecordingDetails(data);
            setLoading(false);
  
            amplitude.track('Recording Data Fetched', { docId, status: data.status });
  
            if (data.status === 'transcribed' && data.filePath) {
              const audioRef = ref(storage, data.filePath);
              getDownloadURL(audioRef)
                .then((url) => {
                  if (isMounted) {
                    setAudioUrl(url);
                    amplitude.track('Audio URL Retrieved', { docId });
                  }
                })
                .catch((error) => {
                  console.error('Error fetching audio URL:', error);
                  amplitude.track('Error Fetching Audio URL', { docId, error: error.message });
                });
            }
          } else {
            setError('Recording details not found.');
            setLoading(false);
            amplitude.track('Error: Recording Details Not Found', { docId });
          }
        }
      },
      (error) => {
        if (isMounted) {
          console.error('Error listening to recording details:', error);
          setError('Error fetching recording details');
          setLoading(false);
          amplitude.track('Error Listening to Recording Details', { docId, error: error.message });
        }
      }
    );
  
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [decodedDocId, docId]); // Ensure 'docId' is included here
  
  const handleCopyText = () => {
    navigator.clipboard.writeText(recordingDetails.transcription || '');
    setCopyStatus('Պատճենված է');
    amplitude.track('Text Copied', { docId });
    setTimeout(() => setCopyStatus('Պատճենել'), 2000);
  };

  const handleDeleteRecording = async () => {
    try {
      const fileRef = ref(storage, recordingDetails.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, 'recordings', decodedDocId));
      amplitude.track('Recording Deleted', { docId });
      navigate('/history');
    } catch (error) {
      console.error('Error deleting recording:', error);
      amplitude.track('Error Deleting Recording', { docId, error: error.message });
    }
  };

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
    closeMenu();
    amplitude.track('Delete Dialog Opened', { docId });
  };
  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? `${hours} ժ ` : ''}${minutes > 0 ? `${minutes} ր ` : ''}${secs} վրկ`;
  };

  const formatDate = (timestamp) => {
    const createdDate = timestamp.toDate();
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const time = createdDate.toLocaleTimeString('hy-AM', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    if (
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear()
    ) {
      return `Այսօր ${time}`;
    } else if (
      createdDate.getDate() === yesterday.getDate() &&
      createdDate.getMonth() === yesterday.getMonth() &&
      createdDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Երեկ ${time}`;
    }

    const day = String(createdDate.getDate()).padStart(2, '0');
    const month = String(createdDate.getMonth() + 1).padStart(2, '0');
    const year = createdDate.getFullYear();
    return `${day}.${month}.${year} ${time}`;
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 4,
            textAlign: 'center',
            backgroundColor: '#fff5f5',
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Paper>
      </Container>
    );
  }

  if (!recordingDetails) {
    return (
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 4,
            textAlign: 'center',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No recording details available.
          </Typography>
        </Paper>
      </Container>
    );
  }

  const { transcription, status, filePath, createdAt, duration, fileType } = recordingDetails;
  const isLongDuration = duration > 120;

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4, mt: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: (theme) => theme.palette.grey[100],
                padding: 2,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" component="h1">
              Ձայնագրության Մանրամասները
            </Typography>
          </Stack>
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
            <MenuItem onClick={openDeleteDialog}>Ջնջել</MenuItem>
          </Menu>
        </Stack>

        <Card elevation={3}>
          <CardContent>
            <Stack spacing={3}>
              {transcription && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Վերծանված տեքստ
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleCopyText}
                    startIcon={<ContentCopyIcon />}
                  >
                    {copyStatus}
                  </Button>
                </Box>
              )}
              {transcription && (
                <Paper
                  variant="outlined"
                  sx={{
                    p: 3,
                    backgroundColor: (theme) => theme.palette.grey[50],
                  }}
                >
                  <Typography variant="body1">{transcription}</Typography>
                </Paper>
              )}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">Անուն:</Typography>
                  <Typography variant="subtitle2">
                    {filePath ? filePath.split('/').pop() : decodedDocId}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Տևողություն:</Typography>
                  <Typography variant="subtitle2">
                    {formatDuration(duration || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Ստեղծվել է:</Typography>
                  <Typography variant="subtitle2">{formatDate(createdAt)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Ֆորմատ:</Typography>
                  <Typography variant="subtitle2">{fileType}</Typography>
                </Grid>
              </Grid>

              <Chip
                label={
                  status === 'transcribed'
                    ? 'Վերծանված'
                    : status === 'processing'
                    ? 'Ընթացքում է'
                    : 'Խափանված'
                }
                color={
                  status === 'transcribed'
                    ? 'success'
                    : status === 'processing'
                    ? 'warning'
                    : 'error'
                }
                sx={{
                  mt: 2,
                  variant: "outlined"
                }}
                variant="outlined"
              />

              {status === 'processing' && isLongDuration && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 'bold' }}>
                    Խոսքը վերածվում է տեքստի։ Քանի որ ձայնագրությունը երկար է,
                    խնդրում ենք փակել այս էջը և վերադառնալ ավելի ուշ։
                  </Typography>
                </Box>
              )}

              {status === 'processing' && !isLongDuration && (
                <Box sx={{ width: '100%' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Խոսքը դարձնում ենք տեքստ։ Խնդրում ենք սպասել։
                  </Typography>
                  <LinearProgress />
                </Box>
              )}

              {audioUrl && (
                <>
                  <Divider />
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Լսել Ձայնագրությունը
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        backgroundColor: (theme) => theme.palette.grey[50],
                      }}
                    >
                      <audio controls style={{ width: '100%' }}>
                        <source src={audioUrl} type="audio/webm" />
                        Ձեր զննարկիչը չի աջակցում աուդիո նվագարկմանը:
                      </audio>
                    </Paper>
                  </Box>
                </>
              )}
            </Stack>
          </CardContent>
        </Card>

        <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
          <DialogTitle>Ջնջել ձայնագրությունը</DialogTitle>
          <Typography sx={{ px: 3, py: 1 }}>
            Այս գործողությունը անդառնալի է, և ձայնագրությունը չի կարող վերականգնվել։
          </Typography>
          <DialogActions>
            <Button onClick={closeDeleteDialog} color="primary">
              Չեղարկել
            </Button>
            <Button onClick={handleDeleteRecording} color="error">
              Ջնջել
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default RecordingDetails;
