import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Typography, Card, CardContent, CircularProgress, LinearProgress, Grid, Divider } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { storage, auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as amplitude from '@amplitude/analytics-browser';

const RecordAudio = () => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const audioChunks = useRef([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();
  const [remainingSeconds, setRemainingSeconds] = useState(null);
  const [recordingComplete, setRecordingComplete] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);

  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const [displayTimer, setDisplayTimer] = useState(0);
  const displayTimerRef = useRef(null);

  useEffect(() => {
    const fetchRemainingSeconds = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setRemainingSeconds(userDoc.data().remaining_seconds || 0);
          }
        } catch (error) {
          console.error('Error fetching remaining seconds:', error);
        }
      }
    };

    fetchRemainingSeconds();
  }, []);

  const startRecording = async () => {
    try {
      amplitude.track('Recording Started');
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000,
          channelCount: 1,
        },
      });
      setAudioStream(stream);

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      startTimeRef.current = new Date();
      setDisplayTimer(0);
      displayTimerRef.current = setInterval(() => {
        setDisplayTimer((prev) => prev + 1);
      }, 1000);

      recorder.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      recorder.onstop = async () => {
        const blob = new Blob(audioChunks.current, { type: 'audio/webm' });
        audioChunks.current = [];
        clearInterval(displayTimerRef.current);
        endTimeRef.current = new Date();

        const duration = Math.round((endTimeRef.current - startTimeRef.current) / 1000);
        setAudioDuration(duration);
        setAudioBlob(blob);

        if (audioStream) {
          audioStream.getTracks().forEach((track) => track.stop());
          setAudioStream(null);
        }
        setMediaRecorder(null);
        setDisplayTimer(duration);

        if (duration > remainingSeconds) {
          setInsufficientCredits(true);
          setRecordingComplete(true);
          amplitude.track('Recording Insufficient Credits', { duration });
        } else {
          amplitude.track('Recording Stopped', { duration });
          uploadAudio(blob, duration);
        }
      };

      recorder.onerror = (error) => {
        console.error('Recorder error:', error);
        amplitude.track('Recording Error', { error: error.message });
        if (audioStream) {
          audioStream.getTracks().forEach((track) => track.stop());
          setAudioStream(null);
        }
        setMediaRecorder(null);
        setRecording(false);
      };

      recorder.start();
      setRecording(true);
      setRecordingComplete(false);
      setInsufficientCredits(false);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      amplitude.track('Recording Microphone Error', { error: error.message });
      alert('Հնարավոր չէ միացնել խոսափողը։');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      setRecording(false);
      clearInterval(displayTimerRef.current);

      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
      }
    }
  };

  const handleBuyCredits = async () => {
    setBuyLoading(true);
    const functions = getFunctions();
    const createOrRetrieveStripeCustomer = httpsCallable(functions, 'createOrRetrieveStripeCustomer');
    try {
      const { data } = await createOrRetrieveStripeCustomer();
      amplitude.track('Credits Purchase Attempt');
      navigate('/pricing', { state: { clientSecret: data.clientSecret } });
    } catch (error) {
      console.error('Error creating or retrieving Stripe customer:', error);
      amplitude.track('Credits Purchase Failed', { error: error.message });
    } finally {
      setBuyLoading(false);
    }
  };

  const uploadAudio = async (blob, duration) => {
    setUploading(true);
    try {
      amplitude.track('Audio Upload Started', { duration });
      const user = auth.currentUser;
      if (!user) {
        alert('Դուք պետք է մուտք գործեք համակարգ, որպեսզի վերբեռնեք ֆայլեր։');
        setUploading(false);
        return;
      }

      const sizeMB = (blob.size / (1024 * 1024)).toFixed(2);
      const recordingsRef = collection(db, 'recordings');
      const docRef = doc(recordingsRef);
      const docId = docRef.id;

      await setDoc(docRef, {
        userId: user.uid,
        status: 'uploading',
        createdAt: serverTimestamp(),
        transcription: '',
        duration,
        fileType: 'audio/webm',
        source: 'recording',
        size: sizeMB,
      });

      const fileName = `${docId}.webm`;
      const storageRef = ref(storage, `recordings/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, blob, {
        customMetadata: {
          uid: user.uid,
          docId,
        },
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload failed:', error);
          amplitude.track('Audio Upload Failed', { error: error.message });
          alert('Ֆայլի վերբեռնումը ձախողվեց։');
          setUploading(false);
        },
        () => {
          setUploading(false);
          setUploadProgress(0);
          amplitude.track('Audio Upload Successful', { docId, duration });
          navigate(`/recording-details/${encodeURIComponent(docId)}`);
        }
      );
    } catch (error) {
      console.error('Error uploading audio:', error);
      amplitude.track('Audio Upload Error', { error: error.message });
      setUploading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours ? hours + ' ժ ' : ''}${minutes} րոպե ${secs} վրկ`;
  };

  const formatDisplayTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 100px)',
        width: '100%',
        gap: 3,
        p: 2,
      }}
    >
      {!recording && !recordingComplete && !uploading && (
        <Card sx={{ maxWidth: 600, width: '100%', textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Ձայնագրիր քո հայերեն խոսքը և դարձրու տեքստ
            </Typography>
            <Button
              variant="contained"
              onClick={startRecording}
              sx={{ mt: 3, px: 4, py: 2 }}
              startIcon={<MicIcon />}
              size="large"
            >
              Սկսել ձայնագրումը
            </Button>
          </CardContent>
        </Card>
      )}

      {recording && (
        <Card sx={{ maxWidth: 600, width: '100%', textAlign: 'center' }}>
          <CardContent>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '4rem', sm: '6rem' },
                fontWeight: 'bold',
                color: 'primary.main',
                mb: 3
              }}
            >
              {formatDisplayTime(displayTimer)}
            </Typography>
            
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Հասանելի ժամանակ՝ {formatTime(remainingSeconds)}
            </Typography>

            <Typography variant="body1" color="text.secondary" gutterBottom>
              Տեքստը կվերծանվի ձայնագրությունը ավարտելուց հետո
            </Typography>

            <Button
              variant="contained"
              color="error"
              onClick={stopRecording}
              sx={{ mt: 3, px: 4, py: 2 }}
              size="large"
            >
              Ավարտել
            </Button>
          </CardContent>
        </Card>
      )}

      {(recordingComplete || uploading) && (
        <Card sx={{ maxWidth: 600, width: '100%' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Ձայնագրության տվյալներ
            </Typography>
            
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2">Տևողություն:</Typography>
                <Typography variant="subtitle2">{formatTime(audioDuration)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Հասանելի ժամանակ:</Typography>
                <Typography variant="subtitle2">{formatTime(remainingSeconds)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Չափս:</Typography>
                <Typography variant="subtitle2">
                  {(audioBlob.size / (1024 * 1024)).toFixed(2)} MB
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {insufficientCredits ? (
              <>
                <Typography variant="body2" color="error" sx={{ mb: 2, textAlign: 'center' }}>
                  Այս ձայնագրությունը վերծանելու համար անհրաժեշտ է գնել ավել վերծանման ժամանակ
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: '#ff5722',
                    '&:hover': { backgroundColor: '#e64a19' },
                  }}
                  onClick={handleBuyCredits}
                  disabled={buyLoading}
                  startIcon={buyLoading ? <CircularProgress size={24} color="inherit" /> : <CreditCardIcon />}
                >
                  Գնել ժամանակ
                </Button>
              </>
            ) : uploading && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  Վերբեռնում {Math.round(uploadProgress)}%
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default RecordAudio;
